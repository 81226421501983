<template>
    <div class="footer">
        <div class="footerLink">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="routeSection-3thsqR">
                            <h5>追蹤 BIGBOX</h5>
                            <ul>
                                <!-- Facebook -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05"
                                       href="https://www.facebook.com/Big-Box%E5%85%B1%E4%BA%AB%E7%89%A9%E6%B5%81-126510436257973/">
                                        <span>Facebook</span>
                                    </a>
                                </li>

                                <!-- Line -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05"
                                       href="https://line.me/R/ti/p/@412aiabg?from=page">
                                        <span>Line</span>
                                    </a>
                                </li>

                                <!-- Instagram -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05"
                                       href="https://www.instagram.com/bigbox.com.tw/">
                                        <span>Instagram</span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="routeSection-3thsqR">
                            <h5>公司介紹</h5>
                            <ul>
                                <!-- 關於我們 -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05" href="/about">
                                        <span>關於我們</span>
                                    </a>
                                </li>

                                <!-- 使命與願景 -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05" href="/vision">
                                        <span>使命與願景</span>
                                    </a>
                                </li>

                                <!-- 品牌故事 -->
<!--                                <li>-->
<!--                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05" href="/story">-->
<!--                                        <span>品牌故事</span>-->
<!--                                    </a>-->
<!--                                </li>-->
                            </ul>
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="routeSection-3thsqR">
                            <h5>政策</h5>
                            <ul>
                                <!-- 隱私權政策 -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05" href="/private_policy">
                                        <span>隱私權政策</span>
                                    </a>
                                </li>

                                <!-- 運送條款 -->
                                <li>
                                    <a class="link-1bIkeG linkDefault-1Ca2JP navLink-2UfL05" href="/terms">
                                        <span>託運條款</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <p>Copyright @ 2021 盒子彈科技股份有限公司.</p>
<!--            <p>電話 04-37077198</p>-->
<!--            <p>台中市龍井區藝術街49號2F</p>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    };
</script>
